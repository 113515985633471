import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { SECRET_SLUG } from 'api/groups/constants';
import type { IGroup } from 'api/groups/types';

import { getAppData } from 'controllers/helpers';

import { WixGroupsController } from '../../controllers';
import { Resolvables, waitForTransition } from '../../router/helpers';

import { addSEOTransitionHandler } from './seo';

import { setupRouter } from './router';

const createController: CreateControllerFn = (params) => {
  const { isMocked } = getAppData(params);
  const { isEditor, isSSR, isPreview } = params.flowAPI.environment;

  const controller = new WixGroupsController(params);

  controller.handleTransition = handleTransition;
  controller.handleSettingsReset = handleSettingsReset;

  if (isMocked) {
    return controller;
  }

  setupRouter(params, controller.vm);
  addSEOTransitionHandler(params);

  if (isEditor || isPreview) {
    controller.router.urlService.rules.initial({
      state: 'group.discussion.feed',
      params: {
        slug: SECRET_SLUG,
      },
    });
  }

  controller.router.transitionService.onBefore(
    { to: 'group.**' },
    async function (transition) {
      const params = transition.params();
      const injector = transition.injector();
      const group: IGroup = await injector.getAsync('group');

      if (group.slug !== params.slug) {
        return transition
          .targetState()
          .withParams({ slug: group.slug })
          .withOptions({ custom: { retain: true } });
      }
    },
  );

  return controller;

  async function handleTransition(resolvables?: Resolvables) {
    return waitForTransition(controller.router, {
      resolvables,
      to: 'group.**',
      disableWaitForResolvables: !isSSR,
      blacklist: [
        'comments',
        'commentsApi',
        // disable media items persistance until media is refactored
        'media',
        // prevent SEO persistance
        'SEO',
      ],
    });
  }

  function handleSettingsReset() {
    controller.handleRouterStateChangeRequest('group.discussion.feed');
  }
};

export default createController;
